import React from 'react'
import SubscriptionModule from './SubscriptionModule'

function WarehouseManagement() {
    return (
        <div>
            <SubscriptionModule pageName="Warehouse Management" />
        </div>
    )
}

export default WarehouseManagement;
