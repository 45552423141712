import React, { useState, useCallback, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/Tracker.scss";
import { Form } from "react-bootstrap";
import profileimg from "../../images/profile-1.png";
import NoDataImg from "../../images/no-data-found.gif";
import { Link, useParams } from "react-router-dom";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import moment from "moment";
import GoogleMapComp from "../GoogleMapComp";
import Select from "react-select";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import AccessDenied from "../../AccessDenied";

function Tracker() {
  const { id } = useParams();

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState(id);
  const [date, setDate] = useState(new Date());
  const [dateTime, setDateTime] = useState("");
  const [trackUserDetails, setTrackUserDetails] = useState([]);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const currentTimeNow = `${new Date().toTimeString().slice(0, 5)}`;

  const [time, setTime] = useState(currentTimeNow);

  const [value, setValue] = useState(new Date());

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("USER-DROPDOWN::", res.result);
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
  };

  // console.log("TIME:", time);

  useEffect(() => {
    const currentTime = time.slice(0, 2);
    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = time.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        setDateTime("0" + exactTime + " " + "PM");
      } else {
        let str = time.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        setDateTime(exactTime + " " + "PM");
      }
    } else if (currentTime == 12) {
      setDateTime(time + " " + "PM");
    } else if (currentTime == 0) {
      let str = time.split("");
      str.splice(0, 2, 12);
      let exactTime = str.join("");
      setDateTime(exactTime + " " + "AM");
    } else {
      setDateTime(time + " " + "AM");
    }
  }, [time]);

  useEffect(() => {
    {
      filterUserId && date && dateTime && fetchTrackUser();
    }
    sessionStorage.setItem("userID", "");
  }, [filterUserId, date, dateTime]);

  const fetchTrackUser = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/Activity/GetCordsUserID?Date=${moment(date).format(
        "DD/MM/YYYY"
      )} ${dateTime}&UserID=${filterUserId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("TRACKE-USER-RES::", res.result);
        if (res.success) {
          setTrackUserDetails(res.result[0]);
          setLatLan({
            lat: parseFloat(res.result[0]?.cordLoc?.latitude),
            lng: parseFloat(res.result[0]?.cordLoc?.longitude),
          });

          // if ( res.result[0].profileIcon == null ||  res.result[0].profileIcon == undefined ||  res.result[0].profileIcon == "" ||  res.result[0].profileIcon == "no_img.png") {
          //    res.result[0].profileIcon = profileimg;
          //    res.result[0].BaseURL = "";
          // } else {
          //   res.result[0].BaseURL = `${baseURL}/`;
          // }
        } else {
          setTrackUserDetails(res.message);
          setLatLan({ lat: null, lng: null });
        }
      })
      .catch((error) => error);
  };

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setLatLan({ lat: null, lng: null });
    setTime(currentTimeNow);
    setDate(new Date());
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* TRACKER TABLE START */}
                <div className="tracker-table">
                  <div className="seleting-menus">
                    <div className="page-title">TRACK USER </div>
                    <div className="page-top-bar-dropdowns">
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={userList}
                        placeholder="Select User"
                        value={filterUserId == "" ? "" : userList.value}
                        onInputChange={userList.label}
                        onChange={(data) => setFilterUserId(data.value)}
                        isSearchable={true}
                      // isClearable={true}
                      />

                      <span className="date-picker-with-icon">
                        <DatePicker
                          selected={date}
                          className="select single-date"
                          dateFormat="dd/MM/yyyy"
                          // selectsEnd
                          // startDate={date}
                          // endDate={toDate}
                          // minDate={fromDate}
                          onChange={(date) => setDate(date)}
                          maxDate={new Date()}
                        />
                        <i className="fa fa-calendar-o calander-icon" />
                      </span>

                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopTimePicker
                      label="For desktop"
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider> */}

                      <TimePicker
                        onChange={setTime}
                        value={time}
                        className="time-select"
                      />

                      <button
                        type="button"
                        className="btn btn-search-track"
                        onClick={clearSerachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-eraser"> </i>
                          <h6>Clear</h6>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="tracker-details-table">
                    <div className="table-responsive tracker-tabel-scroll">
                      <table className="table trackers-list-table">
                        <thead className="thead-light trackers-list-thead">
                          <tr>
                            <th>NAME</th>
                            <th>MOBILE</th>
                            <th>LOCATION</th>
                            <th>DATE</th>
                            <th>WHEN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {trackUserDetails !== "" &&
                            filterUserId !== "" &&
                            date !== "" ? (
                            <tr className="track-list-row-data">
                              <td>
                                <span className="track-list-name d-flex">
                                  <img
                                    className="table-data-track-imgages"
                                    // src={`${filterUserId.BaseURL}${filterUserId.profileIcon}`}
                                    src={profileimg}
                                    alt="John Doe"
                                  />
                                  <h6>{trackUserDetails?.fullName}</h6>
                                </span>
                              </td>
                              <td>{trackUserDetails?.mobile}</td>
                              <td style={{ maxWidth: "200px" }}>
                                {trackUserDetails?.cordLoc?.location}
                              </td>
                              <td>
                                {/* {moment(trackUserDetails?.date).format(
                              "DD/MM/YYYY HH:MM"
                            )} */}
                                {trackUserDetails?.date}
                              </td>
                              <td>{trackUserDetails?.minsHrsAgo}</td>
                            </tr>
                          ) : (
                            <tr className="no-data-found">
                              ------ NO DATA FOUND ------
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="tracker-page-google-map">
                      <div className="google-map-box">
                        {trackUserDetails !== "" &&
                          filterUserId !== "" &&
                          date !== "" ? (
                          <GoogleMapComp
                            className="google-component"
                            pageType="Tracker"
                            latLan={latLan}
                            trackUserDetails={trackUserDetails}
                          />
                        ) : (
                          <>
                            <img
                              src={NoDataImg}
                              className="mCS_img_loaded"
                              style={{ width: "98%" }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* TRACKER TABLE END */}
                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Tracker;
