import React from 'react'
import SubscriptionModule from './SubscriptionModule';

function DealerManagement() {
    return (
        <div>
            <SubscriptionModule pageName="Dealer Management" />
        </div>
    )
}

export default DealerManagement;
