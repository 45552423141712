import React from 'react'
import SubscriptionModule from './SubscriptionModule'

function StockManagement() {
    return (
        <div>
            <SubscriptionModule pageName="Stock Management" />
        </div>
    )
}

export default StockManagement;
