import React from 'react'
import SubscriptionModule from './SubscriptionModule';

function ComplaintManagement() {
    return (
        <div>
            <SubscriptionModule pageName="Complaint Management" />
        </div>
    )
}

export default ComplaintManagement;
