import React from 'react'
import SubscriptionModule from './SubscriptionModule'

function HumanResource() {
    return (
        <div>
            <SubscriptionModule pageName="Human Resource" />
        </div>
    )
}

export default HumanResource
