import React from 'react';
import SubscriptionModule from './SubscriptionModule';

function OrderManagement() {
    return (
        <div>
            <SubscriptionModule pageName="Order Management" />
        </div>
    )
}

export default OrderManagement;
