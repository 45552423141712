import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SkeletonStdTableHead = () => {
  return (
    <tr>
      <th>
        <Skeleton />
      </th>
      <th>
        <Skeleton />
      </th>
      <th>
        <Skeleton />
      </th>
      <th>
        <Skeleton />
      </th>
      <th>
        <Skeleton />
      </th>
      <th>
        <Skeleton />
      </th>
      <th>
        <Skeleton />
      </th>
      <th>
        <Skeleton />
      </th>
      <th>
        <Skeleton />
      </th>
      <th>
        <Skeleton />
      </th>
    </tr>
  );
};

export const SkeletonStdTableBody = () => {
  return (
    <tr>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
    </tr>
  );
};
