import React from 'react'
import SubscriptionModule from './SubscriptionModule'

function ProjectManagement() {
    return (
        <div>
            <SubscriptionModule pageName="Project Management" />
        </div>
    )
}

export default ProjectManagement
