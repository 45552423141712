import React from 'react'
import SubscriptionModule from './SubscriptionModule';

function DistributionManagement() {
    return (
        <div>
            <SubscriptionModule pageName="Distribution Management" />
        </div>
    )
}

export default DistributionManagement;
