import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Leaves.scss";
import "../../styles/ViewSubordinates.scss";
import profileimg from "../../images/profile-1.png";
import ReactPaginate from "react-paginate";
import Users from "./Users";
import UpdateManagerModel from "./UpdateManagerModel";
import ConfirmModel from "../ConfirmModel";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import Select from "react-select";
import {
  optionsRoleList,
  optionsStatusList,
  optionsPageSizeList,
} from "../DropdownsData";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import InternetIssue from "../InternetIssue";

function ViewSubordinates(props) {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [subordinatesList, setSubordinatesList] = useState([]);
  const [managerSubordinates, setManagerSubordinates] = useState([]);
  const [userID, setUserID] = useState("");
  const [updateUserId, setUpdateUserId] = useState("");
  const [activeStatus, setActiveStatus] = useState(2);
  const [totalSubordinates, setTotalSubordinates] = useState("");
  const [updateManagerModel, setUpdateManagerModel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const handleClose = () => setUpdateManagerModel(!updateManagerModel);

  // FETCH SELECT DROPDOWN START
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subordinates/BindUsersDropDown/${props.managerId}`,
      requestOptionZone
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          let subbordinateUsers = res.result.filter(
            (r) => r.userId != props.managerId
          );
          setSubordinatesList(
            subbordinateUsers.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setSubordinatesList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // FETCH SELECT DROPDOWN END

  // FETCH  SUBORDINATES START
  useEffect(() => {
    getManagerSubordinates();
  }, [userID, activeStatus, PageSize]);

  const getManagerSubordinates = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subordinates/GetSubordinates?managerId=${props.managerId}&userId=${userID}&EnableStatus=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOptionZone
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setTotalSubordinates(res.recordCount[1]);
          setManagerSubordinates(
            res.result.filter((data) => data.managerId == props.managerId)
          );
          setPageDataSize(res.result.length);
          setIsLoading(false);
        } else {
          setManagerSubordinates([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // FETCH  SUBORDINATES START

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setUserID("");
    setActiveStatus(2);
  };
  //* CLEAR SELECT END *//

  return (
    <>
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          <div id="main-page">
            <div className="page-wrapper">
              {/* MAIN BODY START */}
              {/* PAGE CONTENT START */}
              {props.subordinateActive ? (
                <div className={`main-body ${props.toggleshift.style}`}>
                  <div className="page-content">
                    {/* USER TABLE START */}

                    <div className="viewsubbordinate-table">
                      <div className="seleting-menus">
                        <div className="page-title">
                          {props.managerName} ({totalSubordinates})
                        </div>
                        <div className="page-top-bar-dropdowns">
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={subordinatesList}
                            placeholder="Select User"
                            value={userID == "" ? "" : subordinatesList.value}
                            onInputChange={subordinatesList.label}
                            onChange={(data) => setUserID(data.value)}
                            isSearchable={true}
                          />

                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={optionsStatusList}
                            placeholder="Select Status"
                            value={optionsStatusList.value}
                            onInputChange={optionsStatusList.label}
                            onChange={(data) => setActiveStatus(data.value)}
                            isSearchable={true}
                          />

                          <button
                            type="button"
                            className="btn btn-search-track"
                            onClick={clearSerachBySelect}
                          >
                            <span className="btn-inline-item d-flex">
                              <i className="fa fa-eraser"> </i>
                              <h6>Clear</h6>
                            </span>
                          </button>

                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            // defaultValue={optionsRoleList[0].label}
                            options={optionsPageSizeList}
                            placeholder="Show Per Page"
                            value={optionsPageSizeList.value}
                            onInputChange={optionsPageSizeList.label}
                            onChange={(data) => setPageSize(data.value)}
                            isSearchable={true}
                          />
                          <button
                            type="button"
                            className="btn btn-go-back"
                            onClick={props.goBacktoUser}
                          >
                            <span className="btn-inline-item d-flex">
                              <i className="fa fa-arrow-left"> </i>
                              <h6>Back</h6>
                            </span>
                          </button>
                        </div>
                      </div>

                      <div className="table-responsive leaves-scroll">
                        <table className="table subbordinate-table">
                          <thead className="thead-light leaves-thead">
                            <tr>
                              <th>NAME</th>
                              <th>CONTACT</th>
                              <th>DESIGNATION</th>
                              <th>DEPARTMENT</th>
                              <th>STATUS</th>
                              <th>ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading ? (
                              <>
                                <SkeletonTableData />
                                <SkeletonTableData />
                                <SkeletonTableData />
                              </>
                            ) : managerSubordinates != "" ? (
                              managerSubordinates.map((item, i) => (
                                <tr className="leaves-list-row-data">
                                  <td>
                                    <span className="users-list-name">
                                      <img
                                        className="table-data-users-imgages"
                                        src={profileimg}
                                        alt="user-img"
                                      />
                                      <h6>{item.userFullName}</h6>
                                    </span>
                                  </td>
                                  <td>
                                    <div>
                                      <strong>Email : </strong>
                                      {item?.email}
                                    </div>
                                    <div>
                                      <strong>Phone : </strong>
                                      {item?.contact}
                                    </div>
                                  </td>
                                  <td>{item?.designation?.designationName}</td>
                                  <td>{item?.designation?.departmentName}</td>
                                  <td>
                                    {item?.enableStatus == 0
                                      ? "InActive"
                                      : "Active"}
                                  </td>
                                  <td>
                                    <button
                                      className="btn remove-manger"
                                      onClick={() => {
                                        setUpdateManagerModel(
                                          !updateManagerModel
                                        );
                                        setUpdateUserId(item.userId);
                                      }}
                                    >
                                      <i className="fa fa-users" />
                                      Assign To
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="no-data-found">
                                <td colSpan={5}>------ NO DATA FOUND ------</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div
                        className="download-and-pagination"
                        style={{ marginBottom: "6px" }}
                      >
                        <div className="data-count-per-page">
                          {pageDataFrom} - {pageDataTill} of {totalData}
                        </div>
                        <span className="user-pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={
                              <i className="fa fa-angle-double-right" />
                            }
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            previousLabel={
                              <i className="fa fa-angle-double-left" />
                            }
                            renderOnZeroPageCount={null}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                          />
                        </span>
                      </div>
                    </div>
                    {/* USER TABLE END */}

                    {/* PAGE CONTENT END*/}
                  </div>
                </div>
              ) : (
                <Users />
              )}
            </div>
          </div>
          {/* UPDATE MANAGER SUBBORDINATE MODEL START */}
          {updateManagerModel ? (
            <UpdateManagerModel
              updateManagerModel={updateManagerModel}
              handleClose={handleClose}
              updateUserId={updateUserId}
              getManagerSubordinates={getManagerSubordinates}
            />
          ) : (
            ""
          )}
          {/* /* UPDATE MANAGER SUBBORDINATE MODEL END */}
        </>
      )}
    </>
  );
}

export default ViewSubordinates;
