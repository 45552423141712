import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, FloatingLabel, Form, ModalFooter, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { loadSubscriptionDropdown, loadUpdateSubscription } from '../../redux/action'
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import SbCancelBtn from '../SbButtons/SbCancelBtn';

const UpGradeSubscriptionModal = (props) => {

  let dispatch = useDispatch();
  const { subscriptionTypeDropdown } = useSelector((state) => state.data);
  const [updatedSub, setUpdatedSub] = useState({ value: "", Label: "" });
  const [subScriptionTypeID, setSubscriptionTypeID] = useState()

  const closeModal = () => {
    props.setSubScribeId();
    props.handleClose(); // Close the modal
  };

  useEffect(() => {
    dispatch(loadSubscriptionDropdown());
  }, []);

  const handleSelectChange = (data) => {
    setUpdatedSub(data)
    setSubscriptionTypeID(data.value)
  }

  const [showAlert, setShowAlert] = useState(false)
  const postUpdateSub = (e) => {
    e.preventDefault();
    if(subScriptionTypeID && props.subscriptionId){
      let updateData = {
        subscriptionTypeID: parseInt(subScriptionTypeID),
        subscriptionId: props.subscriptionId
      }
      console.log(updateData, "updateData")
      let postSubscription = {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.postTravelizeAuth,
        body: JSON.stringify(updateData),
      };
      dispatch(loadUpdateSubscription(postSubscription, closeModal))
    } else {
      setShowAlert(true)
    }
  }

  return (
    <Modal show={props.show} onHide={props.handleClose} className="category-modal-div">
      <Row className='modale-header-div'>
        <Col md={6} xs={9} className='cat-col'>
          <h6 className='page-header-text'>Upgrade Subscription</h6>
        </Col>
        <Col md={6} xs={3} className='cat-col' align="right">

          <button onClick={closeModal} className='bi bi-x close-popup'></button>
        </Col>
      </Row>
      <hr />
      <div className='cat-col'>
        <Form onSubmit={postUpdateSub}>
          <div className='form-field'>
            <FloatingLabel label='Select Subscription' className={`${updatedSub.value !== '' ? "float-visible-select" : "float-hidden float-select"}`}>
              <Select
                className="react-select-container-list model-select"
                classNamePrefix="react-select-list"
                value={!updatedSub.value ? "" : subscriptionTypeDropdown.value}
                onInputChange={subscriptionTypeDropdown.label}
                onChange={(data) => { handleSelectChange(data) }}
                options={subscriptionTypeDropdown}
                placeholder="Select Subscription"
                isSearchable={true}
              // value={updatedSub} 
              // onChange={handleSelectChange}
              />
            </FloatingLabel>
            {(showAlert === true && !subScriptionTypeID) && (
                        <small
                          id={`Error`}
                          className="form-text text-muted "
                        >Please Select Subscription
                        </small>
                      ) }
          </div >

          <hr />
          <div className='sb-btn-section'>
            <SBSaveUpdateBtn btnName="Update" />
            <SbCancelBtn btnName="Cancel" onClickEffect={closeModal} />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpGradeSubscriptionModal;




